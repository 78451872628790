import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="privacy policy" />

<div className="container mt-5">
  <h1>Privacy Policy</h1>
</div>
<p>&nbsp;</p>
<div class="container">
  <p>&nbsp;</p>
  <p>NEWPRICER respects the privacy of internet users visiting the website https://www.newpricer.com/ (“the Website”).</p>
  <p>In this cookie policy (the “Cookie Policy”), “you”, “yours” refer to internet users visiting our Website and “we”, “our”, “us” refer to NEWPRICER.</p>
  <p>The purpose of this Cookie Policy is to provide you with clear and comprehensive information about the manner we use cookies on our Website.</p>
  <p>For further information on how we collect and process your personal data, please see our Terms of use.</p>
  <p>If you have any questions about this Cookie Policy, please contact us at <a href="mailto: newpricer <contact@newpricer.com>">newpricer</a> </p>
  <p>&nbsp;</p>
  <p><strong>1.            What is a cookie?</strong><br />A cookie is a little text files, often encrypted, which stored on the browser or hard drive of your device.</p>
  <p>Cookies are created when the browser of an internet user loads a given internet website: the website sends information (such as a session ID, language, response domain, etc.) to the browser, which then creates a text file.</p>
  <p>Cookies make it possible to store information during their validity period when a browser accesses the various pages of a website or when this browser returns to the website later.</p>
  <p>There are different types of cookies which can be classified according to their purpose:</p>
  <p>&#8211;        Necessary cookies are essential for the proper functioning of a website;</p>
  <p>&#8211;        Analysis and audience measurement cookies are used to make anonymous statistics on how our Website is used; </p>
  <p>&#8211;        Targeting cookies are used to deliver personalized advertising in accordance with your interests;</p>
  <p>&#8211;        Social media cookies are used to track your browser across other websites in order to build a profile of your interest.</p>
  <p>&nbsp;</p>
  <p><strong>2.           What types of cookies do we use?</strong></p>
  <p>[In progress, to be updated].</p>
  <p>&nbsp;</p>
  <p><strong>3.           For what purpose do we use cookies ? </strong><br />We use cookies to :</p>
  <p>&#8211;        Enhance your browsing experience;</p>
  <p>&#8211;        Analyze your visit and monitor our Website’s performance;</p>
  <p>&#8211;        Recognize you when you log in;</p>
  <p>&nbsp;</p>
  <p><strong>4.           Is your consent required for using cookies ? </strong><br />Your consent may be required before placing certain type of cookies on your device, such as targeting cookies or social media cookies for example. <br />Therefore, on your first visit to the Website or if you are using a private browser, an information banner will appear at the bottom of your screen.</p>
  <p>Our information banner allows you to accept the cookies or to settle your preferences by clicking on “Setting the cookies” which enables you to specifically accept or decline each type of cookies according to their purpose.</p>
  <p>Other types of cookies do not require your consent (such as cookies for audience measurement and analysis) but you can object to it.</p>
  <p>&nbsp;</p>
  <p><strong>5.           How to change your preferences or block cookies? </strong><br /> You can withdraw your consent or modify your cookies preferences at any time by clicking on the link [lll8] at the bottom of this Cookie Policy.</p>
  <p>You can also object or withdraw your consent to the use of cookies by configuring your browser as follows:</p>
  <p>&nbsp;</p>
  <p>Chrome</p>
  <p>1.     Choose “Preferences” followed by “Confidentiality and security”, then “settings”,</p>
  <p>2.     Choose the icon “Cookies and website data”</p>
  <p>3.     Select the options that suit you.</p>
  <p>&nbsp;</p>
  <p>Mozilla Firefox</p>
  <p>1.     Select the “Tools” menu followed by “Options”,</p>
  <p>2.     Click on the “Privacy” icon,</p>
  <p>3.     Locate the “Cookie” menu and select the options that suit you.</p>
  <p>&nbsp;</p>
  <p>Internet Explorer 11</p>
  <p>1.     Select the “Tools” menu followed by “Internet Options”,</p>
  <p>2.     Click on the “Confidentiality” tab followed by “Advanced settings”,</p>
  <p>3.     Select the options that suit you.</p>
  <p>&nbsp;</p>
  <p>Safari (v.13.0.2)</p>
  <p>1.     Select the “Safari”, followed by “Preferences”,</p>
  <p>2.     Click on the “Confidentiality” tab,</p>
  <p>3.     Select the options that suit you.</p>
  <p>&nbsp;</p>
  <p><strong>6.           How long do we keep your personal data collected by the cookies? </strong><br /> </p>
  <p>Personal data collected by cookies are kept for no longer than thirteen (13) months after they have been placed on your device.</p>
  <p>As an exception, information collected by cookies for the strict purpose of audience measurements can be stored for a maximum period of (25) months. Such data is aggregated, and IP addresses are deleted or anonymized once localization has been performed. </p>
  <p>You can also decide to delete cookies before the end of these periods into your browser’ settings, by following the instructions in the previous article.</p>
  <p>&nbsp;</p>
  <p><strong>7.           Changes to this Cookie Policy</strong><br />We may update this Cookie Policy in the future. Any significant changes to this Cookies Policy will be notified on our Website to ensure that you are aware of the last updated version.</p>
  <p>&nbsp;</p>
  <p>This Cookie Policy was last updated on November, 15 2020.</p>
  <p>&nbsp;</p>
  <h3> </h3>
</div>





  </Layout>
)

export default PrivacyPage
